export default function Icon(props: any) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.00027 10.155C9.23141 10.155 10.2295 9.19025 10.2295 8.00014C10.2295 6.81004 9.23141 5.84526 8.00027 5.84526C6.76912 5.84526 5.77108 6.81004 5.77108 8.00014C5.77108 9.19025 6.76912 10.155 8.00027 10.155Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.066885 7.60271C1.17201 4.39123 4.30569 2.07422 8.00065 2.07422C11.6939 2.07422 14.8264 4.38908 15.9329 7.59823C16.0223 7.85757 16.0224 8.13818 15.9331 8.39758C14.828 11.6091 11.6943 13.9261 7.99935 13.9261C4.30611 13.9261 1.17362 11.6112 0.0671284 8.40207C-0.0222929 8.14272 -0.022378 7.86211 0.066885 7.60271ZM11.9013 8.00014C11.9013 10.0828 10.1548 11.7712 8.00027 11.7712C5.84576 11.7712 4.09918 10.0828 4.09918 8.00014C4.09918 5.91745 5.84576 4.2291 8.00027 4.2291C10.1548 4.2291 11.9013 5.91745 11.9013 8.00014Z"
        fill="currentColor"
      />
    </svg>
  );
}
